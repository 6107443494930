.wrapper {
  width: 100%;
  background-color: rgba($color: #000000, $alpha: 0.6);
  position: absolute;
  height: 100vh;
  left: 0%;
  top: 0%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}
