.wrapper {
  height: 100vh;
  background-color: rgba(37, 37, 37, 0.6);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5000;
  padding-left: 16px;
  padding-right: 16px;
  &__container {
    width: 100%;
    background-color: #333333;
    padding: 24px 24px 60px 24px;
    border-radius: 9px;
    animation: animateModal 0.8s;
    position: relative;
  }
  &__cancelIcon {
    position: absolute;
    top: 24px;
    right: 24px;
  }
  &__icon {
    margin-top: 43px;
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  &__title {
    margin-bottom: 30px;
    font-family: MatterMedium;
    font-size: 20px;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    color: white;
  }
  &__subtitle {
    margin-bottom: 30px;
    font-family: MatterMedium;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: -0.03em;
    text-align: center;
    color: white;
  }
  @media (min-width: 651px) {
    &__container {
      width: 579px;
    }
  }
  @keyframes animateModal {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}
