.wrapper {
  width: 100%;
  &__songTitle {
    color: #fff;
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    margin-bottom: 16px;
  }
  &__songcoverUpload {
    // width: 200px;
    height: 200px;
    border-radius: 6px;
    border: 1px dashed var(--Gray-200, #4d4d4d);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  &__songFileUpload {
    width: 100%;
    height: 200px;
    border-radius: 6px;
    border: 1px dashed var(--Gray-200, #4d4d4d);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 32px;
  }
  &__checkContainer {
    margin-bottom: 40px;
  }
  &__check {
    margin-left: 12px;
    color: var(--Gray-600, #ccc);
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    span {
      background: var(
        --Audoji-Gradient,
        linear-gradient(90deg, #ef4136 0%, #fbb040 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 22px;
      letter-spacing: -0.42px;
    }
  }
  &__button {
    margin-bottom: 40px;
  }
  &__songFileUploadDisabled {
    cursor: not-allowed;
    background-color: rgb(97, 96, 96, 0.5);
  }
  @media (min-width: 901px) {
    width: 550px;
  }
}
