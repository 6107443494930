.wrapper {
  font-family: matterMedium;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: -0.42px;
  padding: 13px 42px;
  margin-bottom: 11px;
  cursor: pointer;
  span {
    margin-right: 12px;
  }
}

.container {
  color: #fff;
  background: #e05f3f;
}

.inActiveContainer {
  color: #aaa;
  background-color: transparent;
}
