.wrapper {
  padding: 59px 20px;
  background: #1c1c1c;
  height: 100vh;
  overflow-y: auto;
  &__header {
    padding-left: 20px;
    padding-right: 20px;
    a {
      text-decoration: none;
    }
  }
  &__linkContainer {
    width: fit-content;
  }
  &__container {
    margin-top: 27px;
    display: flex;
    justify-content: center;
  }
  &__formContainer {
    width: 100%;
    padding: 30px 20px;
    border-radius: 16px;
  }
  @media (min-width: 901px) {
    padding: 59px 0px;
    &__header {
      padding-left: 89px;
      padding-right: 89px;
    }
    &__formContainer {
      width: 600px;
      padding: 53px 50px;
      border-radius: 16px;
    }
  }
}
