.wrapper {
  display: flex;
  width: fit-content;
  white-space: nowrap;
  align-items: center;
  &__logoContainer {
    width: 48px;
    height: 48px;
    margin-right: 8px;
  }
  &__logoImage {
    width: 40px;
    height: 40px;
    img {
      width: 40px;
      height: 40px;
      object-fit: contain;
    }
  }
  &__title {
    font-family: matterMedium;
    font-size: 26px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 1.3px;
    background: linear-gradient(
      271deg,
      rgba(238, 164, 78, 0.8) 68%,
      rgba(224, 95, 63, 0.8) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  &__subtitle {
    font-family: matterMedium;
    font-size: 11px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.3px;
    background: linear-gradient(
      271deg,
      rgba(238, 164, 78, 0.8) 68%,
      rgba(224, 95, 63, 0.8) 100%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}
