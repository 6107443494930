.wrapper {
  &__sidebar {
    display: block;
  }
  @media (min-width: 951px) {
    &__sidebar {
      display: none;
    }
  }
}
