.wrapper {
  &__inputTitle {
    color: #fff;
    font-family: mattermedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    margin-bottom: 12px;
  }
  &__inputContainer {
    position: relative;
    input {
      width: 100%;
      outline: none;
      padding-left: 24px;
      height: 56px;
      border-radius: 30px;
      border: 1px solid #4d4d4d;
      background-color: transparent;
      font-size: 14px;
      color: white;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #151515 inset !important;
      border: 1px solid #4d4d4d;
      -webkit-text-fill-color: white !important;
      caret-color: white;
    }
  }
  &__inputContainerError {
    position: relative;
    input {
      width: 100%;
      outline: none;
      padding-left: 24px;
      height: 56px;
      border-radius: 30px;
      border: 1px solid rgb(241, 37, 37);
      background-color: transparent;
      font-size: 14px;
      color: rgb(241, 37, 37);
      animation: shake 0.2s ease-in-out 0s 2;
      box-shadow: 0 0 0.6rem #ff0000;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-box-shadow: 0 0 0 30px #151515 inset !important;
      border: 1px solid rgb(241, 37, 37);
      -webkit-text-fill-color: rgb(241, 37, 37) !important;
      caret-color: rgb(241, 37, 37);
    }
    input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: rgb(241, 37, 37);
    }
    input::-moz-placeholder {
      /* Firefox 19+ */
      color: rgb(241, 37, 37);
    }
    input:-ms-input-placeholder {
      /* IE 10+ */
      color: rgb(241, 37, 37);
    }
    input:-moz-placeholder {
      /* Firefox 18- */
      color: rgb(241, 37, 37);
    }
  }
  &__inputRightIcon {
    position: absolute;
    right: 24px;
    top: 29%;
  }
  &__error {
    color: rgb(241, 37, 37);
    font: 8px;
    font-family: matterLight;
  }
  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
}
