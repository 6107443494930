.wrapper {
  &__titleContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    // align-items: center;
  }
  &__title {
    color: var(--White, #fff);
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.66px;
    margin-bottom: 20px;
  }
  &__searchInput {
    flex-grow: 1;
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.42px;
    display: flex;
    justify-content: flex-end;
  }
  &__searchInnerInput {
    width: 100%;
  }
  &__card {
    padding-top: 0px !important;
    margin-bottom: 32px;
  }
  &__soundContainerRowMobile {
    display: flex;
    overflow-x: auto;
    margin-bottom: 26px;
    padding-bottom: 10px;
  }
  &__soundCardMobile {
    margin-right: 20px;
    width: 86px;
  }
  &__soundCards {
    margin-bottom: 32px;
    width: 100%;
  }
  &__soundBoardWrapper {
    display: flex;
    align-items: center;
  }
  &__soundBoardRowContainer {
    flex-grow: 1;
  }
  &__nextCircle,
  &__prevCircle {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 11px;
    margin-right: 11px;
    cursor: pointer;
  }
  @media (min-width: 651px) {
    &__titleContainer {
      display: flex;
      margin-bottom: 32px;
      align-items: center;
      flex-direction: row;
    }
    &__title {
      margin-bottom: 0px;
    }
    &__searchInnerInput {
      width: 322px;
    }
  }
  @media (min-width: 992px) {
    &__card {
      // padding-top: 63px !important;
      margin-bottom: 0px;
    }
  }
  @media (min-width: 1200px) {
    &__title {
      font-size: 22px;
      line-height: 33px;
    }
    &__viewAll {
      font-size: 14px;
      line-height: 22px;
    }
    &__soundCards {
      margin-bottom: 0px;
    }
  }
}
