@font-face {
  font-family: matterMedium;
  src: url("./assets/fonts/matter-font/Matter-Medium.otf");
}

@font-face {
  font-family: matterBold;
  src: url("./assets/fonts/matter-font/Matter-Bold.otf");
}

@font-face {
  font-family: matterSemiBold;
  src: url("./assets/fonts/matter-font/Matter-SemiBold.otf");
}

@font-face {
  font-family: matterRegular;
  src: url("./assets/fonts/matter-font/Matter-Regular.otf");
}

@font-face {
  font-family: matterLight;
  src: url("./assets/fonts/matter-font/Matter-Light.otf");
}

* {
  margin: 0px;
  padding: 0px;
}

.primary-color {
  background: #e05f3f;
}

.body-color {
  background: #181818;
}

.app-wrapper-color {
  background: #151515;
}

.linear-primary-color {
  background: var(
    --Audoji-Gradient,
    linear-gradient(90deg, #ef4136 0%, #fbb040 100%)
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

body {
  padding: 0px;
  margin: 0px;
  background: #151515 !important;
}

/* width */

::-webkit-scrollbar {
  width: 5px;
  height: 3px;
}

/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: silver;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: silver;
}

.react-toast-notifications__container,
.css-nvf14r-ToastContainer {
  z-index: 900000000000000 !important;
}
