.wrapper {
  margin-top: 30px;
  &__title {
    color: var(--Gray-700, #e9e9e9);
    font-family: matterMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.54px;
    margin-bottom: 24px;
    text-transform: capitalize;
  }
  &__tabs {
    margin-bottom: 11px;
  }
  &__recordPreview {
    position: relative;
  }
}
