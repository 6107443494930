/* Set the div size and position */

.wrapper {
  max-width: 700px;
  width: 100%;
  &__title {
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 46px;
    color: rgba(233, 233, 233, 1);
    font-family: "matterMedium";
  }
  &__waveBackground {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #1e1e1e;
    overflow: hidden;
    border-radius: 15px;
    background-image: url("../../../../assets/images/Leading.png");
    background-repeat: repeat-x;
    background-size: contain;
    /* Ensures the wave doesn't spill out */
  }
  // &__isPlayingwaveBackground {
  //     position: relative;
  //     width: 100%;
  //     height: 100%;
  //     background-color: #1e1e1e;
  //     overflow: hidden;
  //     border-radius: 15px;
  // }
  &__isPlayingwaveBackground::before {
    content: "";
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../../../assets/images/Leading.png") repeat-x;
    animation: wave 1.9s linear infinite;
    transform: translateY(-50%);
  }
  /* Create the wave effect using a pseudo-element */
  // &__waveBackground::before {
  //     content: "";
  //     position: absolute;
  //     top: 60%;
  //     left: 0;
  //     width: 100%;
  //     height: 100%;
  //     background: url("../../../../assets/images/Leading.png") repeat-x;
  //     // animation: wave 5s linear infinite;
  //     transform: translateY(-50%);
  // }
  /* Animation keyframes to move the wave */
  @keyframes wave {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  /* Styling the text inside the wave */
  // .wave-text {
  //     color: white;
  //     text-align: center;
  //     padding-top: 70px;
  // }
  /* Container for the custom slider */
  &__sliderContainer {
    position: relative;
    width: 100%;
    height: 30px;
    /* Adjust the height to your preference */
    margin-top: 20px;
    background-color: #ddd;
    border-radius: 10px;
    cursor: pointer;
  }
  /* Background track for the range slider */
  &__sliderTrack {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #f0f0f0;
    /* Light grey background */
    border-radius: 10px;
    z-index: 1;
  }
  /* The highlighted range (selected start/end time) */
  &__sliderRange {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background-color: rgba(0, 0, 255, 0.4);
    /* Light blue to highlight the range */
    border-radius: 10px;
    z-index: 2;
  }
  /* Handle (circle) for the start and end time */
  &__sliderHandle {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 28px;
    /* Size of the handle */
    height: 28px;
    background: linear-gradient(
      90deg,
      rgba(239, 65, 54, 0.8) 0%,
      rgba(251, 176, 64, 0.8) 100%
    );
    /* Blue color */
    border-radius: 50%;
    /* Circle shape */
    cursor: pointer;
    z-index: 3;
    border: 2px solid #fff;
    /* White border around the handle */
  }
  /* Start handle styles */
  &__sliderHandle &__start {
    left: 0;
    /* Start at 0% initially */
  }
  /* End handle styles */
  &__sliderHandle &__end {
    right: 0;
    /* End at 100% initially */
  }
  /* Optional: Hover effects for better UX */
  &__sliderHandle:hover {
    background-color: #0056b3;
    /* Darker blue when hovering */
  }
  /* Optional: Active state when dragging */
  &__sliderHandle:active {
    background-color: #004085;
    /* Even darker blue when actively dragging */
  }
  &__mainSliderHandle {
    // background-color: white;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__mainEndTimeLabel,
  &__mainStartTimeLabel {
    color: white;
    font-size: 14px;
    font-family: "matterMedium";
  }
  &__mainStartTimeSliderHandle {
    margin-right: 10px;
    input[type="range"] {
      -webkit-appearance: none;
      /* Remove default slider appearance */
      width: 100%;
      /* Set width to 100% or your desired width */
      height: 8px;
      /* Set the height of the slider */
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Background of the slider */
      outline: none;
      /* Remove outline on focus */
      border-radius: 5px;
      /* Rounded edges */
      cursor: pointer;
      transition: background 0.3s;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      /* Remove default thumb appearance */
      width: 15px;
      /* Set the width of the thumb */
      height: 15px;
      /* Set the height of the thumb */
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Color of the thumb */
      border-radius: 50%;
      /* Make the thumb round */
      cursor: pointer;
      transition: background 0.3s;
    }
    input[type="range"]::-moz-range-thumb {
      width: 15px;
      height: 15px;
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      border-radius: 50%;
      cursor: pointer;
    }
    input[type="range"]:hover {
      background: #ccc;
      /* Change background on hover */
    }
    input[type="range"]:focus::-webkit-slider-thumb {
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Change thumb color on focus */
    }
    input[type="range"]:focus::-moz-range-thumb {
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
    }
  }
  &__mainEndTimeSliderHandle {
    margin-left: 10px;
    input[type="range"] {
      -webkit-appearance: none;
      /* Remove default slider appearance */
      width: 100%;
      /* Set width to 100% or your desired width */
      height: 8px;
      /* Set the height of the slider */
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Background of the slider */
      outline: none;
      /* Remove outline on focus */
      border-radius: 5px;
      /* Rounded edges */
      cursor: pointer;
      transition: background 0.3s;
    }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      /* Remove default thumb appearance */
      width: 15px;
      /* Set the width of the thumb */
      height: 15px;
      /* Set the height of the thumb */
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Color of the thumb */
      border-radius: 50%;
      /* Make the thumb round */
      cursor: pointer;
      transition: background 0.3s;
    }
    input[type="range"]::-moz-range-thumb {
      width: 15px;
      height: 15px;
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      border-radius: 50%;
      cursor: pointer;
    }
    input[type="range"]:hover {
      background: #ccc;
      /* Change background on hover */
    }
    input[type="range"]:focus::-webkit-slider-thumb {
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
      /* Change thumb color on focus */
    }
    input[type="range"]:focus::-moz-range-thumb {
      background: linear-gradient(
        90deg,
        rgba(239, 65, 54, 0.8) 0%,
        rgba(251, 176, 64, 0.8) 100%
      );
    }
  }
  &__lyricsContainer {
    margin-top: 52px;
    margin-bottom: 46px;
  }
  &__actionsContainer {
    display: flex;
    align-items: flex-end;
    flex-direction: column;
  }
  &__actionsForPlayAndControl {
    display: flex;
    width: 100%;
    height: 100%;
  }
  &__inputContainers {
    flex-grow: 1;
    margin-left: 16px;
    margin-right: 16px;
    display: flex;
    justify-content: space-between;
  }
  &__playContainer {
    width: 40px;
    height: 40px;
    display: none;
    button {
      width: 40px;
      height: 40px;
      border: 1px solid rgba(239, 65, 54, 0.8);
      border-radius: 100%;
      background-color: #181818;
    }
  }
  &__playAndCreateBtn {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  &__createAudojiBtn {
    margin-top: 20px;
    margin-bottom: 20px;
    button {
      padding: 9px 16px;
      font-size: 12px;
      font-family: "matterMedium";
      white-space: nowrap;
    }
  }
  &__plaBtnForMobile {
    visibility: visible;
  }
  &__plaInnerBtnForMobile {
    button {
      padding: 9px 16px;
      font-size: 12px;
      font-family: "matterMedium";
      white-space: nowrap;
      background-color: rgba(239, 65, 54, 0.8);
      color: white;
      text-transform: capitalize;
      border: none;
      border-radius: 30px;
    }
  }
  @media (min-width: 650px) {
    &__actionsContainer {
      display: flex;
      align-items: flex-end;
      flex-direction: row;
      align-items: center;
    }
    &__playContainer {
      display: block;
    }
    &__createAudojiBtn {
      padding-top: 20px;
      margin-top: 0px;
      margin-bottom: 20px;
      height: 100%;
    }
    &__plaBtnForMobile {
      visibility: hidden;
    }
  }
}
