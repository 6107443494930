.wrapper {
  padding: 20px 20px;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    rgba(239, 65, 54, 0.8) 0%,
    rgba(251, 176, 64, 0.8) 100%
  );
  &__icon {
    text-align: center;
  }
  &__title {
    color: #fff;
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-align: center;
    margin-bottom: 8px;
  }
  &__subtitle {
    color: var(--Gray-700, #e9e9e9);
    text-align: center;
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 10px;
  }
  &__btn {
    border-radius: 3px !important;
    background-color: white !important;
    color: #252525 !important;
    font-family: matterMedium;
    font-size: 14px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
