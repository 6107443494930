.wrapper {
  &__title {
    color: var(--White, #fff);
    font-family: matterMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    margin-bottom: 26px;
  }
  &__tabContainer {
    margin-bottom: 42px;
  }
}
