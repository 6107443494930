.wrapper {
  width: 100%;
  position: relative;
  height: 32px;
  input {
    width: 100%;
    outline: none;
    border-radius: 40px;
    background: var(--Gray-100, #252525);
    color: var(--Gray-500, #aaa);
    border: 1px solid #252525;
    font-family: matterLight;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.36px;
    height: 32px;
    padding-left: 26px;
    position: relative;
  }
  input:-ms-input-placeholder {
    color: var(--Gray-500, #aaa);
    font-family: matterLight;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: -0.36px;
  }
  &__searchIcon {
    position: absolute;
    left: 12px;
    display: flex;
    top: 30%;
    align-items: center;
  }
}
