.wrapper {
  &__title {
    color: #fff;
    text-align: center;
    font-family: matterMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    margin-bottom: 4px;
  }
  &__subtitle {
    color: #aaa;
    font-family: matterLight;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-align: center;
    margin-bottom: 32px;
  }
}
