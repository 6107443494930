.wrapper {
  background-size: cover;
  &__fileInput {
    display: none;
  }
  &__inputLable {
    cursor: pointer;
  }
  &__inputLableIcon {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
  &__inputIconCircle {
    width: 42px;
    height: 42px;
    background-color: var(--Gray-100, #252525);
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__inputTitle {
    color: #e9e9e9;
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: -0.36px;
    margin-bottom: 8px;
    text-align: center;
  }
  &__inputSubTitle {
    color: var(--Gray-400, #909090);
    font-family: matterLight;
    font-size: 10px;
    font-weight: 400;
    line-height: 15px;
    /* 150% */
    letter-spacing: -0.3px;
    text-align: center;
  }
}
