.wrapper {
  padding: 14px 10px;
  background-color: #252525;
  border-radius: 8px;
  &__selectedContainer {
    // border: 2px solid #E05F3F;
    border-radius: 8px;
    padding: 14px 8px;
    background-color: #252525;
    width: 100%;
  }
  &__imageContainer {
    width: 100%;
    height: 60px;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 4px;
    }
  }
  &__imageTitle {
    margin-top: 7px;
    color: var(--White, #fff);
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-transform: capitalize;
    flex-grow: 1;
  }
  &__selectedTitle {
    color: #e05f3f;
    flex-grow: 1;
  }
  &__titleContainer {
    display: flex;
    align-items: center;
  }
  &__editIcon {
    margin-left: 4px;
  }
  &__deleteIcon {
    margin-left: 4px;
  }
  &__nextCircle,
  &__prevCircle {
    width: 26px;
    height: 26px;
    background-color: white;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 11px;
    margin-right: 11px;
    cursor: pointer;
  }
  &__prevAndNextMobileContainer {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    right: 0px;
    bottom: 20px;
    visibility: visible;
  }
  @media (min-width: 801px) {
    &__imageContainer {
      width: 100%;
      height: 80px;
    }
    &__prevAndNextContainer {
      display: block;
    }
    &__prevAndNextMobileContainer {
      visibility: hidden;
    }
  }
}
