.wrapper {
  &__stats {
    color: var(--Gray-400, #909090);
    font-family: matterMedium;
    font-size: 10px;
    font-weight: 500;
    line-height: 15px;
    letter-spacing: -0.3px;
    margin-bottom: 32px;
    span {
      color: var(--Gray-600, #ccc);
      font-family: matterMedium;
      font-size: 10px;
      font-weight: 500;
      line-height: 15px;
      letter-spacing: -0.3px;
      margin-left: 3px;
    }
  }
}
