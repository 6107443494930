.wrapper {
  &__title {
    color: #fff;
    text-align: center;
    font-family: matterMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    margin-bottom: 4px;
  }
  &__subtitle {
    color: #aaa;
    font-family: matterLight;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-align: center;
    margin-bottom: 32px;
    span {
      background: var(
        --Audoji-Gradient,
        linear-gradient(90deg, #ef4136 0%, #fbb040 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__otpContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 48px;
  }
  &__resendCode {
    color: var(--Gray-600, #ccc);
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.42px;
    text-align: center;
    margin-bottom: 54px;
    span {
      cursor: pointer;
    }
  }
  &__changeEmail {
    margin-top: 16px;
    color: var(--White, #fff);
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    /* 157.143% */
    letter-spacing: -0.42px;
    text-align: center;
  }
}
