.wrapper {
  padding: 21px 20px;
  background-color: #151515;
  display: flex;
  border-bottom: 1px solid var(--Gray-100, #252525);
  &__inputContainer {
    flex-grow: 1;
  }
  &__avaterContainer {
    display: flex;
    align-items: center;
    span {
      color: var(--White, #fff);
      text-align: right;
      font-family: matterMedium;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: -0.48px;
      margin-left: 12px;
      text-transform: capitalize;
    }
  }
  &__stageName {
    display: none;
  }
  &__menuIcon {
    display: block;
  }
  @media (min-width: 951px) {
    padding: 21px 40px;
    &__stageName {
      display: block;
    }
    &__menuIcon {
      display: none;
    }
  }
}
