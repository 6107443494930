.wrapper {
  width: 100%;
  &__title {
    padding-top: 33px;
    color: var(--White, #fff);
    font-family: matterMedium;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    // margin-bottom: 92px;
  }
  &__body {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &__details {
    width: 100%;
  }
  &__detailsIcon {
    display: flex;
    justify-content: center;
  }
  &__detailsIconContainer {
    width: 100px;
    height: 100px;
    border-radius: 26px;
    background-color: #ffd5d2;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__detailsContent {
    color: #fff;
    text-align: center;
    font-family: matterMedium;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.54px;
    margin-top: 46px;
    margin-bottom: 14px;
  }
  &__detailsSubContent {
    display: flex;
    justify-content: center;
    margin-bottom: 46px;
  }
  &__detailsSubInnerContent {
    color: var(--Gray-600, #ccc);
    text-align: center;
    font-family: matterMedium;
    font-size: 12px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.36px;
    width: 90%;
  }
  &__btnContent {
    display: flex;
    justify-content: center;
    margin-bottom: 46px;
    width: 100%;
    button {
      width: 100%;
      white-space: normal;
    }
  }
  @media (min-width: 650px) {
    &__detailsSubInnerContent {
      width: 60%;
    }
    &__btnContent {
      button {
        width: 40%;
      }
    }
  }
}
