.wrapper {
  &__buttonContainer {
    display: flex;
    width: 100%;
  }
  &__deleteBtn {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
  &__cancelBtn {
    width: 100%;
    padding-left: 8px;
    padding-right: 8px;
  }
}
