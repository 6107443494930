.wrapper {
  height: 100vh;
  background-color: rgba(37, 37, 37, 0.6);
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  z-index: 5000;
  &__container {
    background-color: #151515;
    width: 100%;
    transform: translate(0%, 0);
    animation-delay: 0s;
    /* instead of 1 sec */
    animation-play-state: running;
    animation-iteration-count: 1;
    animation-duration: 1.1s;
    /* instead of 5 sec */
    animation-name: supportanimation;
    opacity: 1;
    /* fade-in effect */
  }
  &__header {
    display: flex;
    align-items: center;
    padding: 61px 20px 37px 20px;
    border-bottom: 1px solid var(--Gray-100, #252525);
  }
  &__title {
    color: #fff;
    font-family: matterMedium;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: -0.54px;
    flex-grow: 1;
  }
  &__bodyContainer {
    padding: 32px 20px;
    overflow-y: auto;
    height: calc(100vh - 131px);
    overflow-x: hidden;
  }
  @media (min-width: 651px) {
    &__container {
      width: 70%;
    }
  }
  @media (min-width: 901px) {
    height: 100vh;
    &__container {
      width: fit-content;
    }
    &__header {
      display: flex;
      align-items: center;
      padding: 61px 40px 37px 40px;
    }
    &__bodyContainer {
      padding: 32px 40px;
      height: 85vh;
    }
  }
  @keyframes supportanimation {
    from {
      transform: translate(200%, 0);
      opacity: 0;
    }
    to {
      transform: translate(0%, 0);
      opacity: 1;
    }
  }
}
