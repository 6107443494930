.wrapper {
  height: 40px;
  display: flex;
  border-radius: 30px;
  border: 1px solid rgba(37, 37, 37, 1);
  &__negative {
    background-color: rgba(37, 37, 37, 1);
    padding: 0px 18px;
    color: white;
    display: flex;
    align-items: center;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    font-size: 18px;
    font-family: "matterBold";
    cursor: pointer;
  }
  &__time {
    flex-grow: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: white;
    font-family: "matterMedium";
    padding-right: 10px;
    padding-left: 10px;
  }
  &__addition {
    background-color: rgba(37, 37, 37, 1);
    padding: 0px 18px;
    color: white;
    display: flex;
    align-items: center;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    font-size: 18px;
    font-family: "matterBold";
    cursor: pointer;
  }
  @media (min-width: 650px) {
    &__time {
      width: 80px;
      padding-left: 0px;
      padding-right: 0px;
    }
  }
}
