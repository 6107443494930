.button {
  width: 100%;
  padding: 17px;
  border: none;
  border-radius: 30px;
  background-color: rgba(224, 95, 63, 1);
  color: white;
  cursor: pointer;
  &__rightIcon {
    margin-right: 6px;
  }
}

.button:disabled {
  background-color: rgba(224, 95, 63, 1);
  cursor: not-allowed;
  opacity: 0.7;
}
