.phoneInputWrapper {
  .flag-dropdown {
    background-color: rgba(21, 21, 21, 1) !important;
    border: 1px solid rgba(77, 77, 77, 1) !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .selected-flag {
    background-color: rgba(21, 21, 21, 1) !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .react-tel-input input::placeholder {
    color: rgba(111, 111, 111, 1);
    /* Change to any color you like */
  }
}

.errorPhoneInputWrapper {
  .flag-dropdown {
    background-color: rgba(21, 21, 21, 1) !important;
    border: 1px solid rgb(241, 37, 37) !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  .selected-flag {
    background-color: rgba(21, 21, 21, 1) !important;
    border-top-left-radius: 30px !important;
    border-bottom-left-radius: 30px !important;
  }
  .react-tel-input input::placeholder {
    color: rgb(241, 37, 37);
    /* Change to any color you like */
  }
  .phone-input-error-message {
    color: rgb(241, 37, 37);
    // font-family: "";
    font-size: 16px;
  }
  .react-tel-input input {
    color: rgb(241, 37, 37);
    animation: shake 0.2s ease-in-out 0s 2;
    box-shadow: 0 0 0.2rem #ff0000;
  }
  @keyframes shake {
    0% {
      margin-left: 0rem;
    }
    25% {
      margin-left: 0.5rem;
    }
    75% {
      margin-left: -0.5rem;
    }
    100% {
      margin-left: 0rem;
    }
  }
}
