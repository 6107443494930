.wrapper {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  a {
    text-decoration: none;
    margin-right: 21px;
    height: 100%;
  }
  &__tab {
    color: var(--Gray-500, #aaa);
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-decoration: none;
    height: 100%;
    white-space: nowrap;
  }
  &__activeTab {
    height: 100%;
    color: rgba(224, 95, 63, 1);
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-decoration: none;
    border-bottom: 1px solid #e05f3f;
    white-space: nowrap;
  }
}
