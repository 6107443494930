.wrapper {
  position: relative;
  &__container {
    display: flex;
  }
  &__sidebarContainer {
    display: none;
  }
  &__dashboardContainer {
    overflow-y: auto;
    height: 100%;
    width: 100%;
    background-color: #181818;
    padding: 33px 20px;
  }
  @media (min-width: 951px) {
    &__sidebarContainer {
      display: block;
      height: calc(100vh - 75px);
      width: 290px;
      padding-top: 23px;
      padding-bottom: 23px;
      border-right: 1px solid var(--Gray-100, #252525);
      background: var(--BG, #151515);
    }
    &__dashboardContainer {
      padding: 33px 32px;
      height: calc(100vh - 75px);
    }
  }
}
