.wrapper {
  &__title {
    color: #fff;
    text-align: center;
    font-family: matterMedium;
    font-size: 22px;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: -0.66px;
    margin-bottom: 4px;
  }
  &__subtitle {
    color: #aaa;
    font-family: matterLight;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-align: center;
    margin-bottom: 32px;
  }
  &__button {
    margin-top: 16px;
    margin-bottom: 16px;
  }
  &__loginRoute {
    color: #ccc;
    font-family: matterMedium;
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.42px;
    text-align: center;
    a {
      background: var(
        --Audoji-Gradient,
        linear-gradient(90deg, #ef4136 0%, #fbb040 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
  &__forgotPassword {
    text-align: right;
    a {
      background: var(
        --Audoji-Gradient,
        linear-gradient(90deg, #ef4136 0%, #fbb040 100%)
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      margin-bottom: 48px;
    }
  }
}
